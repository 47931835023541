import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//#region Prototypes
const shortDay = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
const longDay = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
const shortMonth = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
const longMonth = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

declare global {
  interface Date {
    format(format: string): string;
  }
  interface Number {
    padding(pad: number): string;
  }
}

Date.prototype.format = function (format): string {
  const date = new Date(this.valueOf());
  
  format = format.replace(/d{4}/g, longDay[date.getDay()])
  format = format.replace(/d{3}/g, shortDay[date.getDay()])
  format = format.replace(/d{2}/g, date.getDate().padding(2))

  format = format.replace(/M{4}/g, longMonth[date.getMonth()]);
  format = format.replace(/M{3}/g, shortMonth[date.getMonth()]);
  format = format.replace(/M{2}/g, (date.getMonth() + 1).padding(2));
  
  format = format.replace(/y{4}/g, date.getFullYear().toString());
  format = format.replace(/y{2}/g, date.getFullYear().toString().substring(2));
  
  format = format.replace(/H{2}/g, date.getHours().padding(2));
  format = format.replace(/m{2}/g, date.getMinutes().padding(2));
  format = format.replace(/s{3}/g, date.getMilliseconds().padding(3));
  format = format.replace(/s{2}/g, date.getSeconds().padding(2));

  // format = format.replace(/(^|[^d])(d{2})(?=$|[^d])/g, date.getDate().padding(2));
  // format = format.replace(/(^|[^d])(d{3})(?=$|[^d])/g, shortDay[date.getDay()]);
  // format = format.replace(/(^|[^d])(d{4})(?=$|[^d])/g, longDay[date.getDay()]);

  // format = format.replace(/(^|[^M])(M{2})(?=$|[^M])/g, (date.getMonth() + 1).padding(2));
  // format = format.replace(/(^|[^M])(M{3})(?=$|[^M])/g, shortMonth[date.getMonth()]);
  // format = format.replace(/(^|[^M])(M{4})(?=$|[^M])/g, longMonth[date.getMonth()]);
  
  // format = format.replace(/(^|[^y])(y{2})(?=$|[^y])/g, date.getFullYear().toString().substring(2));
  // format = format.replace(/(^|[^y])(y{4})(?=$|[^y])/g, date.getFullYear().toString());
  
  // format = format.replace(/(^|[^H])(H{2})(?=$|[^H])/g, date.getHours().padding(2));
  // format = format.replace(/(^|[^m])(m{2})(?=$|[^m])/g, date.getMinutes().padding(2));
  // format = format.replace(/(^|[^s])(s{2})(?=$|[^s])/g, date.getSeconds().padding(2));
  // format = format.replace(/(^|[^s])(s{3})(?=$|[^s])/g, date.getMilliseconds().padding(3));

  return format;
}

Number.prototype.padding = function(pad: number): string {
  let result = '';
  const numSplit = this.valueOf().toString().split('.');
  let padCount: number = pad - numSplit[0].length;
  while (padCount > 0) {
    result += '0';
    padCount--;
  }

  return result += numSplit.join('.');
}
//#endregion

const app = createApp(App).use(store).use(router)
  .component('Sections', defineAsyncComponent(() => import('@/components/Sections.vue')))
  .component('LabelText', defineAsyncComponent(() => import('@/components/LabelText.vue')));

app.mount("#app");
