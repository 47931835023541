
/* eslint-disable */
import { defineComponent, onMounted, Ref, ref, watch } from 'vue';
import jsPDF, {} from 'jspdf';

export default defineComponent({
  components: {
  },
  setup() {
    //#region Data
    const pdfSrc = ref('');
    const pdfObject = ref('');
    const pdfTextData = ref([
      {
        background: 'Borang Carian A4-1.png',
        format: 'a4',
        orientation: 'p',
        fields: [
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 35,
            y: 109,
            w: 100,
            h: 200,
            options: {
              maxWidth: 145
            },
            key: 'sectiona-fullname',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 84,
            y: 119,
            w: 100,
            h: 200,
            options: {},
            key: 'sectiona-ic',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 35,
            y: 130,
            w: 100,
            h: 200,
            options: {
              maxWidth: 145
            },
            key: 'sectiona-address',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 60,
            y: 140,
            w: 100,
            h: 200,
            options: {},
            key: 'sectiona-contact',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 88,
            y: 162,
            w: 100,
            h: 200,
            options: {
              maxWidth: 46
            },
            key: 'sectionb-husbandname',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 138,
            y: 162,
            w: 100,
            h: 200,
            options: {
              maxWidth: 46
            },
            key: 'sectionb-wifename',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 88,
            y: 176,
            w: 100,
            h: 200,
            options: {
              maxWidth: 46
            },
            key: 'sectionb-husbandic',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 138,
            y: 176,
            w: 100,
            h: 200,
            options: {
              maxWidth: 46
            },
            key: 'sectionb-wifeic',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 88,
            y: 181,
            w: 100,
            h: 200,
            options: {
              maxWidth: 95
            },
            key: 'sectionb-tempatnikah',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 88,
            y: 198,
            w: 100,
            h: 200,
            options: {
              maxWidth: 46
            },
            key: 'sectionb-husbandphone',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 138,
            y: 198,
            w: 100,
            h: 200,
            options: {
              maxWidth: 46
            },
            key: 'sectionb-wifephone',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 100,
            y: 204,
            w: 100,
            h: 200,
            options: {
              maxWidth: 35
            },
            key: 'sectionb-tarikhnikahhijrah',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 151,
            y: 204,
            w: 100,
            h: 200,
            options: {
              maxWidth: 33
            },
            key: 'sectionb-tarikhnikahmasihi',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 100,
            y: 210.5,
            w: 100,
            h: 200,
            options: {
              maxWidth: 35
            },
            key: 'sectionb-tarikhceraihijrah',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 151,
            y: 210.5,
            w: 100,
            h: 200,
            options: {
              maxWidth: 33
            },
            key: 'sectionb-tarikhceraimasihi',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 100,
            y: 217,
            w: 100,
            h: 200,
            options: {
              maxWidth: 35
            },
            key: 'sectionb-tarikhpembatalanhijrah',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 151,
            y: 217,
            w: 100,
            h: 200,
            options: {
              maxWidth: 33
            },
            key: 'sectionb-tarikhpembatalanmasihi',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 100,
            y: 225,
            w: 100,
            h: 200,
            options: {
              maxWidth: 35
            },
            key: 'sectionb-tarikhrujukhijrah',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 151,
            y: 225,
            w: 100,
            h: 200,
            options: {
              maxWidth: 33
            },
            key: 'sectionb-tarikhrujukmasihi',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 8,
            x: 88,
            y: 231,
            w: 100,
            h: 200,
            options: {
              maxWidth: 95
            },
            key: 'sectionb-tempatnikahcerairujukbatal',
            fontStyle: 'bold'
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 44,
            y: 260,
            w: 100,
            h: 200,
            options: {},
            key: 'tarikh',
            fontStyle: 'bold'
          },
        ]
      },
      {
        background: 'Borang Carian A4-2.png',
        format: 'a4',
        orientation: 'p',
        fields: [
          {
            type: 'image',
            value: '',
            fontSize: 10,
            x: 58.5,
            y: 59,
            w: 7,
            h: 7,
            options: {},
            key: 'jenis_perkahwinan',
            fontStyle: 'bold',
          },
          {
            type: 'image',
            value: '',
            fontSize: 10,
            x: 94.3,
            y: 59,
            w: 7,
            h: 7,
            options: {},
            key: 'jenis_perceraian',
            fontStyle: 'bold',
          },
          {
            type: 'image',
            value: '',
            fontSize: 10,
            x: 151,
            y: 59,
            w: 7,
            h: 7,
            options: {},
            key: 'jenis_pembatalan',
            fontStyle: 'bold',
          },
          {
            type: 'image',
            value: '',
            fontSize: 10,
            x: 178.6,
            y: 59,
            w: 7,
            h: 7,
            options: {},
            key: 'jenis_rujuk',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 55,
            y: 90,
            w: 100,
            h: 100,
            options: {
              maxWidth: 125,
            },
            key: 'maklumatdaftar_namasuami',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 81,
            y: 96.25,
            w: 100,
            h: 100,
            options: {
              maxWidth: 99,
            },
            key: 'maklumatdaftar_icsuami',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 53,
            y: 102.5,
            w: 100,
            h: 100,
            options: {
              maxWidth: 127,
            },
            key: 'maklumatdaftar_namaisteri',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 81,
            y: 108.75,
            w: 100,
            h: 100,
            options: {
              maxWidth: 99,
            },
            key: 'maklumatdaftar_icisteri',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 139,
            y: 115,
            w: 100,
            h: 100,
            options: {
              maxWidth: 41,
            },
            key: 'maklumatdaftar_tarikhnikah',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 145,
            y: 121.25,
            w: 100,
            h: 100,
            options: {
              maxWidth: 35,
            },
            key: 'maklumatdaftar_bildaftar',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 118,
            y: 127.5,
            w: 100,
            h: 100,
            options: {
              maxWidth: 62,
            },
            key: 'maklumatdaftar_tempat',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 26,
            y: 166,
            w: 100,
            h: 100,
            options: {
              maxWidth: 157,
              lineHeightFactor: 1.5,
            },
            key: 'maklumatdaftar_ulasan',
            fontStyle: 'bold',
          },
          {
            type: 'string',
            value: '',
            fontSize: 10,
            x: 47,
            y: 233,
            w: 100,
            h: 100,
            options: {},
            key: 'maklumatdaftar_tarikh',
            fontStyle: 'bold',
          },
        ]
      }
    ])
    const pdfModelValues: Ref<any> = ref({});
    const showPDF = ref(false);
    const isGenerating = ref(false);

    const perkahwinanCheck = ref(false);
    const perceraianCheck = ref(false);
    const pembatalanCheck = ref(false);
    const rujukCheck = ref(false);
    //#endregion

    //#region Lifecycle
    onMounted(() => {
    })
    //#endregion
    
    //#region Methods
    const createPDF = () => {
      pdfObject.value = '';
      showPDF.value = true;
      isGenerating.value = true;
      
      setTimeout(async () => {
        await generatingPDF();
        isGenerating.value = false;
      }, 500);
    }
    const populateValues = () => {
      pdfTextData.value.forEach(t => {
        t.fields.forEach(f => {
          f.value = pdfModelValues.value[f.key] || '';
        })
      });
      
      const tarikhObj = pdfTextData.value[0].fields.find(f => f.key == 'tarikh');
      if (tarikhObj) {
        tarikhObj.value = new Date().format('dd/MM/yyyy');
      }
      const tarikhDaftar = pdfTextData.value[1].fields.find(f => f.key == 'maklumatdaftar_tarikh');
      if (tarikhDaftar) {
        tarikhDaftar.value = new Date().format('dd/MM/yyyy');
      }

      const perkahwinanCheckObj = pdfTextData.value[1].fields.find(f => f.key == 'jenis_perkahwinan');
      const perceraianCheckObj = pdfTextData.value[1].fields.find(f => f.key == 'jenis_perceraian');
      const pembatalanCheckObj = pdfTextData.value[1].fields.find(f => f.key == 'jenis_pembatalan');
      const rujukCheckObj = pdfTextData.value[1].fields.find(f => f.key == 'jenis_rujuk');
      if (perkahwinanCheckObj) perkahwinanCheckObj.value = perkahwinanCheck.value ? 'Tick.png' : '';
      if (perceraianCheckObj) perceraianCheckObj.value = perceraianCheck.value ? 'Tick.png' : '';
      if (pembatalanCheckObj) pembatalanCheckObj.value = pembatalanCheck.value ? 'Tick.png' : '';
      if (rujukCheckObj) rujukCheckObj.value = rujukCheck.value ? 'Tick.png' : '';
    }
    const generatingPDF = () => {
      const prom = new Promise((res: any) => {
        populateValues();

        const pdfDoc = new jsPDF({
          unit: 'mm',
          compress: true
        });

        pdfTextData.value.forEach((d, i) => {
          pdfDoc.addPage(d.format, d.orientation as any);
          pdfDoc.setPage(i + 2);

          pdfDoc.addImage(require(`@/assets/${d.background}`), 'png', 0, 0, 210, 297);

          d.fields.forEach(f => {
            pdfDoc.setFontSize(f.fontSize);
            pdfDoc.setFont('times', f.fontStyle);
            if (f.type == 'string') {
              pdfDoc.text(f.value.toUpperCase(), f.x, f.y, f.options);
              if (f.key == 'maklumatdaftar_ulasan') {
                const textRow = f.value.split('\n');
                let currentRow = 0;
                //#region Simple one
                textRow.forEach(t => {
                  const textLength = pdfDoc.getTextWidth(t.toUpperCase());
                  if (textLength > 0) {
                    const numOfLines = Math.ceil(textLength / (f.options?.maxWidth ? f.options.maxWidth : textLength));
                    for (let x = 0; x < numOfLines; x++) {
                      pdfDoc.line(26, 167 + (currentRow * 5.3), 26 + (f.options?.maxWidth ? f.options?.maxWidth : 0), 167 + (currentRow * 5.3));
                      currentRow++;
                    }
                  } else {
                    currentRow++;
                  }
                });
                //#endregion
                //#region Complicated one
                // textRow.forEach(t => {
                //   const textLength = pdfDoc.getTextWidth(t.toUpperCase());
                //   const numOfLines = Math.floor(textLength / (f.options?.maxWidth ? f.options.maxWidth : textLength));
                //   if (numOfLines > 0) {
                //     for (let x = 0; x < numOfLines; x++) {
                //       pdfDoc.line(26, 167 + (currentRow * 5.3), 26 + (f.options?.maxWidth ? f.options.maxWidth : textLength), 167 + (currentRow * 5.3));
                //       currentRow++;
                //     }
                //   }
                //   const lastLine = textLength % (f.options?.maxWidth ? f.options.maxWidth : textLength);
                //   if (lastLine > 0) {
                //     pdfDoc.line(26, 167 + (currentRow * 5.3), 26 + (numOfLines == 0 ? lastLine : lastLine + 2.7), 167 + (currentRow * 5.3));
                //   }
                //   currentRow++;
                // });
                //#endregion
              }
            } else if (f.type == 'image' && f.value) {
              pdfDoc.addImage(require(`@/assets/${f.value}`), 'png', f.x, f.y, f.w, f.h);
            }
          })
        });

        pdfDoc.deletePage(1);

        pdfDoc.setProperties({
          title: `Borang Carian ${pdfModelValues.value['sectiona-ic'] ? `(${pdfModelValues.value['sectiona-ic']})` : ''}`
        });
        pdfObject.value = URL.createObjectURL(new Blob([ pdfDoc.output('blob') ], { type : 'application/pdf' }));
        res();
      });

      return prom;
    }
    const checkboxChanged = (key: string) => {
      perkahwinanCheck.value = false;
      perceraianCheck.value = false;
      pembatalanCheck.value = false;
      rujukCheck.value = false;

      if (key == 'perkahwinanCheck') {
        perkahwinanCheck.value = true;
      } else if (key == 'perceraianCheck') {
        perceraianCheck.value = true;
      } else if (key == 'pembatalanCheck') {
        pembatalanCheck.value = true;
      } else if (key == 'rujukCheck') {
        rujukCheck.value = true;
      }
    }
    //#endregion

    //#region Watcher
    watch(showPDF, (val) => {
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = val ? 'hidden' : 'auto';
    })
    //#endregion
    
    return {
      //#region Data
      pdfSrc,
      pdfObject,
      pdfTextData,
      pdfModelValues,
      showPDF,
      isGenerating,

      perkahwinanCheck,
      perceraianCheck,
      pembatalanCheck,
      rujukCheck,
      //#endregion
      
      //#region Methods
      createPDF,
      checkboxChanged,
      //#endregion
    }
  },
})
